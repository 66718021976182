<template>
  <component :is="rowData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="rowData === undefined"
    >
      <h4 class="alert-heading">
        Gagal menemukan data
      </h4>
      <div class="alert-body">
        Tidak ada data ditemukan, Cek
        <b-link         class="alert-link"
          :to="{ name: 'jenis-kegiatan-list'}"
        >
          Disini
        </b-link>
        untuk melihat data lainnya.
      </div>
    </b-alert>

    <template v-if="rowData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <b-tabs
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
          >
            <!-- general tab -->
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Jenis Kegiatan</span>
              </template>

              <edit-info-card
                :row-data="rowData"
              />
            </b-tab>
            <!--/ general tab -->
          </b-tabs>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col>
      </b-row> -->

    </template>

  </component>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import getJenisAsset from '@/connection-api/master'
import storeModule from '@/connection-api/jenis-kegiatan/storeModule'
import EditInfoCard from './EditInfoCard.vue'
// import ViewDokumenLainnya from '../element/ViewDokumenLainnya.vue'
// import ViewDokumenFoto from '../element/ViewDokumenFoto.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    // BLink,
    EditInfoCard,
  },
  data() {
    return {
      assetId: null,
    }
  },
  async beforeMount() {
    this.assetId = Number(this.$route.params.id)
  },
  methods: {
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    mappingSelectOptionIds(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.ids,
          })
        })
      }
    },
  },
  setup() {
    const rowData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'jenis-kegiatan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('jenis-kegiatan/fetchData', { id: router.currentRoute.params.id })
      .then(response => { rowData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          rowData.value = undefined
        }
      })

    return {
      rowData,
    }
  },
}
</script>

<style>

</style>
